<template>
  <div class="confirm-page">
    <div class="order-info p15 bgf">
      <div class="info-row flex flex-align-start mb10">
        <div class="info-label cl4a mr20">订单编号</div>
        <div class="info-value cl17 flex flex-align-center">
          {{ props.orderDetail.order_no }}
          <img
            class="copy-icon ml10"
            src="@/assets/img/qr_check/copy.svg"
            @click="handleCopyText(props.orderDetail.order_no)"
            alt=""
          />
        </div>
      </div>
      <div class="info-row flex flex-align-start mb10">
        <div class="info-label cl4a mr20">商品名称</div>
        <div class="info-value cl17 flex flex-align-center">
          {{ props.orderDetail.goods_title }}
        </div>
      </div>
      <!--b2c以下三个无，为：实付金额 支付时间 可用时间-->
      <div class="info-row flex flex-align-start mb10">
        <div class="info-label cl4a mr20">兑换积分</div>
        <div class="info-value cl17 flex flex-align-center">
          {{ props.orderDetail.goods_score }} 积分
        </div>
      </div>
      <div class="info-row flex flex-align-start mb10">
        <div class="info-label cl4a mr20">兑换时间</div>
        <div class="info-value cl17 flex flex-align-center">
          {{ props.orderDetail.paid_time }}
        </div>
      </div>
      <div class="info-row flex flex-align-start mb10">
        <div class="info-label cl4a mr20">可用时间</div>
        <div class="info-value cl17 flex flex-align-center">
          {{ showTime(props.orderDetail) }}
        </div>
      </div>
    </div>

    <div class="goods-detail pb75">
      <div class="detial-title pt10 pb10 fs14 cl99 tc">———— 详情说明 ————</div>
      <div
        v-for="item in props.orderDetail.order_details_img"
        :key="item"
        class="image-box"
      >
        <img :src="item" alt="详情图" />
      </div>
    </div>
    <div class="bottom-btn-box">
      <div class="send-btn" @click="onConfirm">
        <div class="send-text">确认核销</div>
      </div>
    </div>
  </div>
</template>
<script setup>
// import { defineEmit } from 'vue'
import { checkQr } from '../../service/checkQr.service.js'
import { WXRegister, setClipboardData } from '../../utils/nwechat'
import { Toast } from 'vant'

const props = defineProps({
  orderDetail: {
    type: Object,
    default: () => {}
  },
  pageStatus: {
    type: String,
    default: ''
  },
  score_user_id: {
    type: String,
    default: ''
  },
  time: {
    type: Number,
    default: 0
  }
})

const emits = defineEmits(['update:pageStatus', 'notify'])

// 展示时间
const showTime = (detail) => {
  if (detail.goods_validity_time_type == 'absolute_time') {
    return `${detail.goods_validity_start_time} 至 ${detail.goods_validity_end_time}`
  } else {
    return `${detail.validity_start_time} 至 ${detail.validity_end_time}`
  }
}

// 复制文本
async function handleCopyText(text) {
  try {
    // iphone 存在 某一次复制不成功现象  errMsg: "setClipboardData:permission denied
    await WXRegister()
    await setClipboardData(text)
    Toast('复制成功')
  } catch (e) {
    console.log('复制失败error====>', e)
    Toast('复制失败，请重试或者自行选择进行复制')
  }
}

// 确认核销
const onConfirm = async () => {
  if (props.time - new Date() < 0) {
    emits('update:pageStatus', 'failDate')
    return
  }
  const { code, data } = await checkQr({
    score_order_id: props.orderDetail.score_order_id,
    score_user_id: props.score_user_id
  }).catch((e) => {
    if (e.code == 0 && e.msg == '核销中，请稍后再试') {
      Toast('正在核销中，请稍后')
      setTimeout(() => {
        onConfirm()
      }, 2000)
    }
  })

  if (code == 200) {
    // 成功
    emits('update:pageStatus', 'success')
  } else if (code == 40404) {
    // 已被核销
    emits('update:pageStatus', 'failMore')
  } else if (code == 40405) {
    // 已过核销时间
    emits('update:pageStatus', 'failOverdue')
  } else if (code == 40406) {
    // 还未到可用时间
    emits('update:pageStatus', 'failCheck')
  }
  emits('notify', data)
}
</script>
<style lang="less">
@import url('~@/assets/style/bottom_btn.less');
.info-row {
  font-size: 12px;
  line-height: 16px;

  .info-value {
    font-weight: 500;
  }

  .info-label {
    min-width: 48px;
  }
  .copy-icon {
    display: block;
    width: 16px;
    height: 16px;
  }

  .order-img {
    display: block;
    width: 60px;
    height: 60px;
  }
}

.image-box {
  width: 100%;
  img {
    display: block;
    width: 100%;
    height: auto;
  }
}
</style>
